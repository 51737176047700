<template>
  <ul class="ul-wrap">
    <li class="isdefault current" v-if="selectedAddress">
      <div class="bd">
        <div class="receiver">
          <span>
            {{ selectedAddress.consignee }}
          </span>
          <i class="el-icon-circle-check"></i>
        </div>
        <div class="addr">
          {{ selectedAddress.provinceName }} {{ selectedAddress.cityName }}
          {{ selectedAddress.zoneName }} {{ selectedAddress.addressDetail }}
        </div>
        <div class="phone">{{ selectedAddress.mobile }}</div>
      </div>
      <div class="btm">
        <p class="default"><span v-show="selectedAddress.isDefault === '1'">默认地址</span></p>
        <div class="btn_wrap">
          <ChooseAddress @selectAddress="selectAddress"></ChooseAddress>&nbsp;
          <AddressManagement :id="selectedAddress.id" @sureRefresh="sureRefresh" ref="edit">
            <template>
              <el-button @click.stop="$refs['edit'].show()" type="text" style="color: #ab0033">
                编辑
              </el-button>
            </template>
          </AddressManagement>
        </div>
      </div>
    </li>
    <AddressManagement @sureRefresh="sureRefresh" ref="add">
      <template>
        <li class="new" @click.stop="$refs['add'].show()">
          <div class="bd">
            <p class="el-icon-plus iconfont-xx"></p>
            <p class="txt">新增地址</p>
          </div>
        </li>
      </template>
    </AddressManagement>
  </ul>
</template>

<script>
import AddressManagement from '@/components/AddressManagement';
import ChooseAddress from './ChooseAddress';
import { getById, getMyDefault, listMyPage } from '@/api/address/address';
export default {
  name: 'AddressDetail',
  components: {
    AddressManagement,
    ChooseAddress,
  },
  data() {
    return {
      selectedAddress: {},
    };
  },
  mounted() {
    this.getDefaultAddress();
  },
  methods: {
    // 获取默认地址
    getDefaultAddress() {
      getMyDefault()
        .then(response => {
          if (response.data) {
            this.selectedAddress = response.data;
            this.$emit('address', this.selectedAddress.id);
            this.$emit('addressDetailInfo', this.selectedAddress);
            return;
          }
          this.fetchAddressListOne();
        })
        .finally(() => {});
    },
    // 通过id查询地址信息
    getAddressById() {
      getById(this.selectedAddress.id)
        .then(response => {
          this.selectedAddress = response.data;
        })
        .finally(() => {});
    },
    // 当无默认地址的时候获取收货地址列表,并选择第一条展示
    fetchAddressListOne() {
      listMyPage({}).then(response => {
        this.selectedAddress = response.data[0];
        if (response.data[0]) {
          this.$emit('address', this.selectedAddress.id);
          this.$emit('addressDetailInfo', this.selectedAddress);
        }
      });
    },
    // 地址操作新增/编辑之后 数据刷新
    sureRefresh(value) {
      console.log(value);
      if (value.id || this.selectedAddress) {
        this.getAddressById();
        return;
      }
      this.getDefaultAddress();
    },
    // 切换收货地址
    selectAddress(value) {
      this.selectedAddress = value;
      this.$emit('address', this.selectedAddress.id);
      this.$emit('addressDetailInfo', this.selectedAddress);
    },
  },
};
</script>

<style lang="scss" scoped>
@import './AddressDetail';
</style>
