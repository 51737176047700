<template>
  <div class="settlement">
    <h3 class="title">选择收货地址</h3>
    <div class="addr_list">
      <AddressDetail @address="address" @addressDetailInfo="getAddressDetail"></AddressDetail>
    </div>
    <MemberIdcard
      v-if="type === 'global' || type === 'GLOBAL'"
      @menberid="menberid"
      @saveIdcard="saveIdcard"
    >
      <div class="box_hd">
        <h3 class="title">实名认证<span>为保证报关成功，请保持与支付实名认证身份一致</span></h3>
      </div>
    </MemberIdcard>
    <h3 class="title">确认订单信息</h3>
    <CheckoutGoods
      :goodsList="goodsList"
      :payment="payment"
      :checkoutPriceData="checkoutPriceData"
      :menberidVal="menberidVal"
      :distributorPartyRelationId="distributorPartyRelationId"
      :isYearlyRecommendType="isYearlyRecommendType"
      @coupongChange="coupongChange"
      @virtualCreditChange="virtualCreditChange"
      @contractChange="contractChange"
    />
  </div>
</template>

<script>
import AddressDetail from '@/components/views/checkout/AddressDetail';
import CheckoutGoods from '@/components/views/checkout/CheckoutGoods';
import MemberIdcard from '@/components/views/checkout/MemberIdcard';
import { calculatePayment, sampleCalculatePayment } from '@/api/checkout';
import { recommendOrderCalculatePayment } from '@/api/recommended-list/index';
export default {
  name: 'checkout',
  components: {
    AddressDetail,
    CheckoutGoods,
    MemberIdcard,
  },
  computed: {
    // 商品列表
    commodities() {
      if (this.goodsList.length > 0) {
        return this.goodsList.map(item => {
          return {
            commodityId: item.commodityId,
            quantity: item.quantity,
            shoppingCartId: item.id || '',
            skuId: item.skuId,
          };
        });
      }
    },
    // 赠品列表
    giftList() {
      let giftList = [];
      //  如果存在赠品，获取赠品列表
      if (this.payment.marketingDiscountVOS && this.payment.marketingDiscountVOS.length > 0) {
        this.payment.marketingDiscountVOS.forEach(item => {
          if (['GIFT_OFF', 'SALE_POLICY'].includes(item.activityType)) {
            giftList = item.giftDetailVOS;
          }
        });
      }
      // 赠品数据格式转化接口需要的格式
      giftList = giftList.map(item => {
        return {
          quantity: item.quantity,
          skuId: item.skuId,
        };
      });
      return giftList;
    },
    // 结算接口传参
    calculateData() {
      const obj = {};
      obj.commodities = this.commodities;
      obj.userCouponId = this.userCouponId;
      if (this.recommendId && this.goodsType === 'recommended') {
        obj.recommendId = this.recommendId;
      }
      obj.distributorContractInfoId = this.distributorContractInfoId;
      return obj;
    },
    // 创建订单传参
    checkoutPriceData() {
      const obj = {};
      if (this.goodsType === 'sample') {
        // 是小样
        // console.log('goodsList', this.goodsList);
        obj.addressId = this.consigneeAddressId;
        obj.sampleId = this.goodsList[0].sampleId;
        if (this.type === 'global' || this.type === 'GLOBAL') {
          obj.buyerName = this.buyerName;
          obj.idcardNo = this.idcardNo;
          obj.customerTel = this.addressDetailInfoData.mobile;
        }
      } else {
        obj.commodities = this.commodities;
        obj.consigneeAddressId = this.consigneeAddressId;
        obj.userCouponId = this.userCouponId;
        obj.userGifts = this.giftList;
        if (this.recommendId && this.goodsType === 'recommended') {
          obj.recommendId = this.recommendId;
        }
      }

      return obj;
    },
    // 是否为年框商品清单
    isYearlyRecommendType() {
      return this.recommendType === 'YEARLY';
    },
  },
  data() {
    return {
      type: this.$route.query.type,
      goodsType: this.$route.query.goodsType || 'ordinary', // 下单商品种类  小样 sample   普通 ordinary    清单详情  recommended
      goodsList: JSON.parse(sessionStorage.getItem('orderDetail')),
      payment: {},
      userCouponId: '',
      consigneeAddressId: '', // 收货地址Id
      virtualCreditChecked: true,
      menberidVal: false, // 实名认证id
      buyerName: '',
      idcardNo: '',
      recommendId: this.$route.query.recommendId || null, // 清单详情ID
      addressDetailInfoData: {}, // 收货地址信息
      distributorContractInfoId: '', // 合同主体ID
      recommendType: this.$route.query.recommendType || null, // 清单类型
      distributorPartyRelationId: this.$route.query.distributorPartyRelationId || null, // 分销商主数据渠道ID
    };
  },
  methods: {
    getAddressDetail(value) {
      this.addressDetailInfoData = value;
    },
    // 地址
    address(value) {
      // console.log('value', value);
      this.consigneeAddressId = value;
    },
    menberid(val) {
      this.menberidVal = val;
      // console.log(this.menberidVal);
    },
    saveIdcard(val) {
      this.buyerName = val.buyerName;
      this.idcardNo = val.idcardNo;
    },
    fetchPayment() {
      if (this.goodsType === 'ordinary') {
        this.calculatePayment();
      } else if (this.goodsType === 'sample') {
        this.sampleCalculatePayment();
      } else if (this.goodsType === 'recommended') {
        this.calculatePayment('recommended');
      }
    },
    // 普通订单结算
    calculatePayment(goodsType = '') {
      let req = '';
      switch (goodsType) {
        case 'recommended':
          // 是清单详情结算
          req = recommendOrderCalculatePayment;
          break;
        default:
          // 普通订单结算
          req = calculatePayment;
      }
      this.calculateData.isUseVirtualCredit = this.virtualCreditChecked ? '1' : '0';
      req(this.calculateData).then(response => {
        if (response.code === '0') {
          this.payment = response.data;
          return;
        }
      });
    },
    // 小样订单结算
    sampleCalculatePayment() {
      sampleCalculatePayment(this.goodsList[0].sampleId).then(response => {
        if (response.code === '0') {
          const { firstLevel, secondLevel, threeLevel } = response.data;
          this.payment = {
            ...response.data,
            payAmount: response.data.price,
            specCode: response.data.skuId,
            firstLevel,
            secondLevel,
            threeLevel,
          };
          return;
        }
      });
    },
    // 选择的优惠券改变
    coupongChange(value) {
      this.userCouponId = value;
      this.fetchPayment();
    },
    // 是否返利
    virtualCreditChange(val) {
      this.virtualCreditChecked = val;
      this.fetchPayment();
    },
    // 合同主体选择
    contractChange(val) {
      this.virtualCreditChecked = true;
      this.distributorContractInfoId = val;
      this.fetchPayment();
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import './index';
</style>
